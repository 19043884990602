import React from "react"
import PropTypes from "prop-types"

// Components
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostNav from "../components/postNav"
import PostCard from "../components/postCard"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"
var capitalize = function(str) {
  if (!str || typeof str !== "string") return str
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

const Tags = ({ pageContext, data }, location) => {
  let postCounter = 0
  const { tag } = pageContext
  const upperTag = capitalize(tag)
  const siteTitle = upperTag
  const { edges } = data.allMarkdownRemark
  // const tagHeader = `${totalCount} post${totalCount === 1 ? "" : "s"
  //   } tagged with "${upperTag}"`
  const tagHeader = `${upperTag}`
  return (
    <Layout title={siteTitle}>
      <SEO
        title={upperTag}
        keywords={[
          `creator`,
          `unlimish`,
          `portfolio`,
          `motion graphics`,
          `design`,
        ]}
      />
      <header className="page-head">
        <h2 className="page-head-title">{tagHeader}</h2>
      </header>
      <PostNav />
      <div id="post-feed">
        {edges.map(({ node }) => {
          postCounter++
          return (
            <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div>
      {/* {edges.map(({ node }) => {
                  const { slug } = node.fields
                  const { title } = node.frontmatter
                  return (
                      <li key={slug}>
                          <Link to={slug}>{title}</Link>
                      </li>
                  )
              })} */}
      {/*This links to a page that does not yet exist.
        You'll come back to it!*/}
      <style id="style"></style>
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
