import React from "react"
import { Link } from "gatsby"

export default props => (
  <article
    className={`post-card ${props.node.frontmatter.tags} ${props.count % 0 ===
      0 && `post-card-large`} ${props.postClass} ${
      props.node.frontmatter.thumbnail ? `with-image` : `no-image`
    }`}
  >
    <img
      src={`${props.node.frontmatter.thumbnail.childImageSharp.fluid.src}`}
      sizes={`${props.node.frontmatter.thumbnail.childImageSharp.fluid.sizes}`}
      width="100%"
      height="100%"
      loading="lazy"
      alt=""
    />
    <Link to={props.node.fields.slug} className="post-card-link">
      <div className="post-card-content">
        <h2 className="post-card-title">
          {props.node.frontmatter.title || props.node.fields.slug}
        </h2>
      </div>
    </Link>
  </article>
)
