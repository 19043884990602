import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearchMinus, faSearchPlus } from "@fortawesome/free-solid-svg-icons"

export default props => (
  <div className="post-nav">
    <div className="post-nav-left">
      <button id="show-all">All</button>
      <button id="show-video">Video</button>
      <button id="show-design">Design</button>
      <button id="show-music">Music</button>
    </div>
    <div className="post-nav-right">
      <FontAwesomeIcon icon={faSearchPlus} id="zoom-in" />
      <input
        type="range"
        min="1"
        max="8"
        defaultValue="3"
        step="1"
        id="slider"
      />
      <FontAwesomeIcon icon={faSearchMinus} id="zoom-out" />
    </div>
  </div>
)
